import { AuthContext } from "../../context/AuthContext";
import { useGetGlobalSearchQuery } from "../../services/search";
import { Search as SearchIcon } from "@mui/icons-material/";
import { CircularProgress, InputBase, Paper, MenuItem, Typography } from "@mui/material/";
import { styled, alpha } from "@mui/material/styles";
import debounce from "lodash.debounce";
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";


const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  display: "flex",
  alignItems: "center",
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginRight: theme.spacing(1),
  },
  zIndex: "119",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // Use theme.breakpoints to handle font size for different screen sizes
  [theme.breakpoints.down("sm")]: {
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
    },
  },
  [theme.breakpoints.up("sm")]: {
    "& .MuiSvgIcon-root": {
      fontSize: "1.5rem",
    },
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0.5),
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const SpinnerWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const ResultsDropdown = styled(Paper)(({ theme }) => ({
  position: "absolute",
  top: "100%",
  width: "100%",
  maxHeight: "30rem",
  overflowY: "auto",
  zIndex: "11199",
}));

const getMatchingSubstring = (content, query) => {
  const matchIndex = content.toLowerCase().indexOf(query.toLowerCase());
  if (matchIndex === -1) return "";

  const start = Math.max(matchIndex - 10, 0);
  const end = Math.min(matchIndex + query.length + 10, content.length);

  return "..." + content.substring(start, end) + "...";
};

function stripHtmlTags(str) {
  if (!str) return "";
  return str.replace(/<\/?[^>]+(>|$)/g, "");
}

const highlightMatch = (text, query) => {
  const strippedText = stripHtmlTags(text);
  const escapedQuery = query.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
  const parts = strippedText.split(new RegExp(`(${escapedQuery})`, "gi"));
  return {
    __html: parts
      .map((part) =>
        part.toLowerCase() === query.toLowerCase()
          ? `<mark>${part}</mark>`
          : part
      )
      .join(""),
  };
};

export default function SearchBar() {
  const navigate = useNavigate();
  const { userId } = useContext(AuthContext);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [showResults, setShowResults] = useState(false);
  const searchRef = useRef(null);

  // Debounce the query input change
  const debouncedSearch = useCallback(
    debounce((query) => setDebouncedQuery(query), 500), // Adjust the delay (500ms) as needed
    []
  );

  const {
    data: globalSearchResults,
    isLoading,
    isFetching,
  } = useGetGlobalSearchQuery(
    { userId, query: debouncedQuery },
    { skip: !userId || !debouncedQuery }
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setQuery("");
        setShowResults(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [searchRef]);

  const handleSearchResultClick = (path) => {
    setQuery("");
    setShowResults(false);
    navigate(path);
  };

  const formattedPosts = globalSearchResults?.posts?.map((post) => ({
    ...post,
    matchHint: getMatchingSubstring(
      post?.content || post?.postTitle,
      debouncedQuery
    ),
  }));
  const formattedComments = globalSearchResults?.comments?.map((comment) => ({
    ...comment,
    matchHint: getMatchingSubstring(comment?.content, query),
  }));
  const formattedReplies = globalSearchResults?.replies?.map((reply) => ({
    ...reply,
    matchHint: getMatchingSubstring(reply?.content, query),
  }));
  const formattedFiles = globalSearchResults?.files?.map((file) => ({
    ...file,
    matchHint: getMatchingSubstring(file?.description, query),
  }));

  return (
    <Search
      ref={searchRef}
      sx={{
        // Regular styles
        color: "var(--search-bar-text)",
        backgroundColor: "var(--search-bar-bg)",

        // Hover styles
        "&:hover": {
          backgroundColor: "var(--search-bar-hover-bg)", // Example variable for hover background
          color: "var(--search-bar-hover-text)", // Example variable for hover text color
        },
      }}
    >
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search…"
        inputProps={{ "aria-label": "search" }}
        value={query}
        sx={{ color: "var(--search-bar-text)" }}
        onChange={(e) => {
          const newQuery = e.target.value;
          setQuery(newQuery);  // Immediate query update for UI feedback
          setShowResults(true);
          debouncedSearch(newQuery);  // Trigger the debounced search
        }}
      />
      {(isLoading || isFetching) && (
        <SpinnerWrapper>
          <CircularProgress size={24} />
        </SpinnerWrapper>
      )}
      {query && showResults && !(isLoading || isFetching) && (
        <ResultsDropdown>
          {globalSearchResults?.categories &&
            globalSearchResults?.categories?.length > 0 &&
            globalSearchResults?.categories?.map((category) => (
              <MenuItem
                key={category?._id}
                onClick={() =>
                  handleSearchResultClick(
                    `/category/${category?.path}/${category?._id}`
                  )
                }
              >
                <Typography noWrap>Category: {category?.name}</Typography>
              </MenuItem>
            ))}
          {globalSearchResults?.users &&
            globalSearchResults?.users?.length > 0 &&
            globalSearchResults?.users?.map((user) => (
              <MenuItem
                key={user._id}
                onClick={() =>
                  handleSearchResultClick(`/member-profile/${user?._id}`)
                }
              >
                <Typography noWrap>
                  Member: {user?.firstName} {user?.lastName}
                </Typography>
              </MenuItem>
            ))}
          {globalSearchResults?.posts &&
            globalSearchResults?.posts?.length > 0 &&
            globalSearchResults?.posts?.map((post) => (
              <MenuItem
                key={post?._id}
                onClick={() => handleSearchResultClick(`/post/${post?._id}`)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography noWrap>
                  {post?.categoryDetails?.name}: {post?.postTitle}
                </Typography>
                {formattedPosts &&
                  formattedPosts?.length > 0 &&
                  formattedPosts
                    ?.filter((filteredPost) => filteredPost._id === post._id)
                    ?.map((filteredPost) => (
                      <Typography
                        key={filteredPost?._id}
                        variant="body2"
                        color="textSecondary"
                        dangerouslySetInnerHTML={highlightMatch(
                          filteredPost.matchHint,
                          query
                        )}
                      />
                    ))}
              </MenuItem>
            ))}
          {globalSearchResults?.comments &&
            globalSearchResults?.comments?.length > 0 &&
            globalSearchResults?.comments?.map((comment) => (
              <MenuItem
                key={comment?._id}
                onClick={() =>
                  handleSearchResultClick(`/post/${comment?.post}`)
                }
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography noWrap>Comment: {comment?.content}</Typography>
                {formattedComments &&
                  formattedComments?.length > 0 &&
                  formattedComments
                    ?.filter(
                      (filteredComment) => filteredComment._id === comment._id
                    )
                    ?.map((filteredComment) => (
                      <Typography
                        key={filteredComment?._id}
                        variant="body2"
                        color="textSecondary"
                        dangerouslySetInnerHTML={highlightMatch(
                          filteredComment?.matchHint,
                          query
                        )}
                      />
                    ))}
              </MenuItem>
            ))}
          {globalSearchResults?.replies &&
            globalSearchResults?.replies?.length > 0 &&
            globalSearchResults?.replies?.map((reply) => (
              <MenuItem
                key={reply?._id}
                onClick={() =>
                  handleSearchResultClick(`/post/${reply?.comment?.post}`)
                }
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography noWrap>Reply: {reply?.content}</Typography>
                {formattedReplies &&
                  formattedReplies?.length > 0 &&
                  formattedReplies
                    ?.filter((filteredReply) => filteredReply._id === reply._id)
                    ?.map((filteredReply) => (
                      <Typography
                        key={filteredReply?._id}
                        variant="body2"
                        color="textSecondary"
                        dangerouslySetInnerHTML={highlightMatch(
                          filteredReply?.matchHint,
                          query
                        )}
                      />
                    ))}
              </MenuItem>
            ))}
          {globalSearchResults?.files &&
            globalSearchResults?.files?.length > 0 &&
            globalSearchResults?.files?.map((file) => (
              <MenuItem
                key={file?._id}
                onClick={() => handleSearchResultClick(`/files`)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography noWrap>file: {file?.name}</Typography>
                {formattedFiles &&
                  formattedFiles?.length > 0 &&
                  formattedFiles
                    ?.filter((filteredFile) => filteredFile._id === file._id)
                    ?.map((filteredFile) => (
                      <Typography
                        key={filteredFile?._id}
                        variant="body2"
                        color="textSecondary"
                        dangerouslySetInnerHTML={highlightMatch(
                          filteredFile?.matchHint,
                          query
                        )}
                      />
                    ))}
              </MenuItem>
            ))}
        </ResultsDropdown>
      )}
    </Search>
  );
}