import Navbar from "./components/auth/navbar";
import ErrorBoundary from "./components/errors/ErrorBoundary";
import GlobalAnnouncement from "./components/helpers/GlobalAnnouncement";
import Sidebar from "./components/sidebar/Sidebar";
// Ensure the path is correct based on your file structure
import SidebarTiersItemAppBar from "./components/sidebar/SidebarTiersItemAppBar";
import { AuthContext } from "./context/AuthContext";
import { MemberProfilePageContext } from "./context/MemberProfilePageContext";
import { SidebarContext } from "./context/SidebarContext";
import { useSocket } from "./context/SocketContext";
import { routes, protectedRoutes } from "./routes";
import GlobalNotification from "./services/be-app-notofications/GlobalNotification";
import { isTokenExpired } from "./services/custom-hooks/helpUtils";
import ProtectedRoute from "./services/routes/ProtectedRoute";
import { Box, CircularProgress } from "@mui/material";
import React, { useContext, Suspense, memo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.scss";
import HealthCheck from "./components/HealthCheck";

function App() {
  const { token, accountType } = useContext(AuthContext);
  const { sidebarOpen } = useContext(SidebarContext);
  const { memberProfilePageActive } = useContext(MemberProfilePageContext);
  const isAuthenticated = token && !isTokenExpired(token);
  const { basicNotifications, removeBasicNotification } = useSocket();

  const containerClass = isAuthenticated
    ? sidebarOpen
      ? memberProfilePageActive
        ? "main-content sidebar-open member-profile-page-active"
        : "main-content sidebar-open"
      : memberProfilePageActive
      ? "main-content sidebar-closed member-profile-page-active"
      : "main-content sidebar-closed"
    : "main-content no-sidebar";

  return (
    <Router>
      <ErrorBoundary>
        <Box className="app-container">
          {isAuthenticated && <Navbar className="navbar" />}
          {isAuthenticated && <SidebarTiersItemAppBar />}
          {accountType !== "reosadmin" && <GlobalAnnouncement />}
          <div className="app-body">
            {isAuthenticated && <Sidebar />}
            <div className={containerClass}>
              <Suspense fallback={<CircularProgress />}>
                <Routes>
                  {routes?.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      element={route.element}
                    />
                  ))}
                  {protectedRoutes?.map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      element={<ProtectedRoute>{route.element}</ProtectedRoute>}
                    />
                  ))}
                  <Route path="/health" element={<HealthCheck />} />

                  <Route
                    path="*"
                    element={
                      isAuthenticated ? (
                        <Navigate to="/" />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                  />
                </Routes>
              </Suspense>
            </div>
          </div>
          {isAuthenticated &&
            basicNotifications &&
            basicNotifications?.length > 0 && (
              <GlobalNotification
                basicNotifications={basicNotifications}
                removeBasicNotification={removeBasicNotification}
              />
            )}
        </Box>
      </ErrorBoundary>
    </Router>
  );
}

export default memo(App);
