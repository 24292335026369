import { AuthContext } from "../../../context/AuthContext";
import {
  useGetMemberQRCodesQuery,
  useCreateUpdateMemberQRMutation,
  useDeleteMemberQRCodesMutation,
} from "../../../services/member";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Slider,
  CircularProgress,
} from "@mui/material";
import imageCompression from "browser-image-compression";
import { QRCodeSVG } from "qrcode.react";
import React, { useState, useContext, useEffect } from "react";

const MemberQRTab = ({ member }) => {
  const {
    data: qrCodes,
    isLoading,
    error,
  } = useGetMemberQRCodesQuery({ memberId: member._id });

  const [createUpdateMemberQR] = useCreateUpdateMemberQRMutation();
  const [deleteMemberQR] = useDeleteMemberQRCodesMutation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState("");
  const [showErrors, setShowErrors] = useState(false);
  const [open, setOpen] = useState(false);
  const [editQRCode, setEditQRCode] = useState(null);
  const [newQRCode, setNewQRCode] = useState({
    name: "",
    url: "",
    description: "",
    size: 375,
  });

  const [imagePreview, setImagePreview] = useState(null);
  const [urlError, setUrlError] = useState(""); // URL validation error
  const [loading, setLoading] = useState(false);
  const [qrCodeDimensions, setQrCodeDimensions] = useState({});

  const handleSliderChange = (e, newValue) => {
    setNewQRCode((prev) => ({ ...prev, size: newValue }));
  };

  const resizeImage = async (file) => {
    const options = {
      maxSizeMB: 0.3, // Maximum size in MB
      maxWidthOrHeight: 1920, // Maximum width or height
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      const img = new Image();
      img.src = URL.createObjectURL(compressedFile);
      img.onload = () => {
        URL.revokeObjectURL(img.src); // Release the object URL
      };
      return compressedFile;
    } catch (error) {
      console.error("Error resizing image:", error);
      return file;
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setLoading(true);

      if (file.size > 300 * 1024) {
        setFileError(
          "File size exceeds the 300KB limit. Resizing... Please wait"
        );
        const resizedFile = await resizeImage(file);

        const reader = new FileReader();
        reader.onload = (event) => {
          setImagePreview(event.target.result);
          setNewQRCode((prevQRCode) => ({
            ...prevQRCode,
            qrImage: resizedFile,
          }));
        };
        reader.readAsDataURL(resizedFile);

        setSelectedFile(resizedFile);
        setImagePreview(null);
      } else {
        setFileError("");
        setSelectedFile(file);

        const reader = new FileReader();
        reader.onload = (event) => {
          setImagePreview(event.target.result);
          setNewQRCode((prevQRCode) => ({
            ...prevQRCode,
            qrImage: file,
          }));
        };
        reader.readAsDataURL(file);
      }
      setLoading(false);
    }
  };

  const handleDeleteImage = () => {
    setNewQRCode({ ...newQRCode, qrImage: null });
    setImagePreview(null);
    setSelectedFile(null);
  };

  const handleOpen = (qrCode = null) => {
    setEditQRCode(qrCode);
    setNewQRCode(
      qrCode || {
        name: "",
        url: "",
        description: "",
        size: 375,
      }
    );
    setSelectedFile(null);
    setImagePreview(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowErrors(false);
    setFileError("");
    setUrlError("");
    setImagePreview(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewQRCode((prev) => ({ ...prev, [name]: value }));
    if (name === "url") {
      const urlPattern = /^(http:\/\/|https:\/\/)/;
      if (!urlPattern.test(value)) {
        setUrlError("URL must start with http:// or https://");
      } else {
        setUrlError("");
      }
    }
  };

  const handleSave = async () => {
    const urlPattern = /^(http:\/\/|https:\/\/)/;

    if (!newQRCode.name || !newQRCode.url || !urlPattern.test(newQRCode.url)) {
      setShowErrors(true);
      if (!urlPattern.test(newQRCode.url)) {
        setUrlError("URL must start with http:// or https://");
      }
      return;
    }

    const formData = new FormData();
    formData.append("name", newQRCode.name);
    formData.append("url", newQRCode.url);
    formData.append("description", newQRCode.description || "");
    formData.append("size", newQRCode.size || 375);
    formData.append("_id", newQRCode._id || null);

    if (selectedFile) {
      formData.append("qrImage", selectedFile);
      const img = new Image();
      img.src = URL.createObjectURL(selectedFile);
      img.onload = () => {
        formData.append("width", img.width);
        formData.append("height", img.height);
        URL.revokeObjectURL(img.src); // Release the object URL
      };
    }

    try {
      await createUpdateMemberQR({
        memberId: member._id,
        qrCodeId: editQRCode ? editQRCode._id : null,
        updateData: formData,
      }).unwrap();
      handleClose();
    } catch (error) {
      console.error("Failed to save QR code:", error);
    }
  };

  const handleDelete = async (qrId) => {
    try {
      await deleteMemberQR({ memberId: member._id, qrId }).unwrap();
    } catch (error) {
      console.error("Failed to delete QR code:", error);
    }
  };

  const handleDeleteAll = async () => {
    try {
      await deleteMemberQR({ memberId: member._id }).unwrap();
    } catch (error) {
      console.error("Failed to delete all QR codes:", error);
    }
  };

  function bufferToBase64(buffer) {
    // Convert the buffer to a Uint8Array
    const uint8Array = new Uint8Array(buffer);

    // Use the built-in btoa() function after converting to a string
    let binaryString = "";
    for (let i = 0; i < uint8Array.length; i++) {
      binaryString += String.fromCharCode(uint8Array[i]);
    }

    // Convert binary string to Base64
    return btoa(binaryString);
  }

  const calculateImageSettings = async (qrCode) => {
    if (qrCode.qrImage) {
      const maxDimension = qrCode.size * 0.42;
      let width = qrCode.width;
      let height = qrCode.height;
      if (!width || !height) {
        const base64String = bufferToBase64(qrCode.qrImage.data);
        const img = new Image();
        img.src = `data:image/png;base64,${base64String}`;

        await new Promise((resolve, reject) => {
          img.onload = () => {
            width = img.width;
            height = img.height;

            resolve();
          };
          img.onerror = (error) => {
            console.error("Error loading image:", error);
            reject(error);
          };
        });
      }
      const aspectRatio = width / height;
      const calculatedWidth =
        aspectRatio >= 1 ? maxDimension : maxDimension * aspectRatio;
      const calculatedHeight =
        aspectRatio >= 1 ? maxDimension / aspectRatio : maxDimension;
      return {
        width: calculatedWidth,
        height: calculatedHeight,
      };
    }
  };

  const downloadQRCode = (qrCode) => {
    const svgElement = document.getElementById(`qr-code-${qrCode._id}`);
    const svgData = new XMLSerializer().serializeToString(svgElement);
    const canvas = document.createElement("canvas");
    const svgSize = svgElement.getBoundingClientRect();
    canvas.width = qrCode.size;
    canvas.height = qrCode.size;
    const ctx = canvas.getContext("2d");

    const img = new Image();
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      const pngUrl = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = pngUrl;
      link.download = `${qrCode.name}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // }
    };
  };

  useEffect(() => {
    const loadQrCodeDimensions = async () => {
      const dimensions = {};
      for (const qrCode of qrCodes) {
        const settings = await calculateImageSettings(qrCode);
        dimensions[qrCode._id] = settings;
      }
      setQrCodeDimensions(dimensions);
    };

    if (qrCodes) {
      loadQrCodeDimensions();
    }
  }, [qrCodes]);

  if (isLoading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error loading QR codes</Typography>;

  return (
    <div style={{ padding: "20px", marginBottom: "200px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">QR Codes</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpen()}
          >
            Create New QR Code
          </Button>
          {qrCodes?.length > 0 && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDeleteAll}
              style={{ marginLeft: "10px" }}
            >
              Delete All QR Codes
            </Button>
          )}
        </Grid>
        {qrCodes?.map((qrCode) => (
          <Grid item xs={12} sm={6} md={4} key={qrCode._id}>
            <Card>
              <CardContent>
                <Typography variant="h6">{qrCode.name}</Typography>
                <Typography variant="body2" sx={{ marginBottom: 3 }}>
                  <a
                    href={qrCode.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {qrCode.url}
                  </a>
                </Typography>

                {/* QR Code with image overlay */}
                {qrCode.url && (
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    {/* Render QR code */}
                    <QRCodeSVG
                      id={`qr-code-${qrCode._id}`}
                      value={qrCode.url}
                      style={{ width: "100%" }}
                      size={qrCode.size}
                      level={"H"}
                      imageSettings={
                        qrCode.qrImage && qrCode.qrImage?.data
                          ? {
                              src: `data:image/png;base64,${bufferToBase64(
                                qrCode.qrImage.data
                              )}`,

                              x: undefined, // Center horizontally
                              y: undefined, // Center vertically

                              ...qrCodeDimensions[qrCode._id],

                              excavate: false, // Make the center of the QR code transparent to see the logo clearly
                            }
                          : null
                      }
                    />
                  </div>
                )}
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => handleOpen(qrCode)}
                >
                  Edit
                </Button>
                <Button
                  size="small"
                  color="secondary"
                  onClick={() => handleDelete(qrCode._id)}
                >
                  Delete
                </Button>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => downloadQRCode(qrCode)}
                >
                  Download{" "}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {editQRCode ? "Edit QR Code" : "Create New QR Code"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              required
              value={newQRCode.name}
              onChange={handleChange}
              error={!newQRCode.name && showErrors}
              helperText={
                !newQRCode.name && showErrors ? "Name is required." : ""
              }
            />
            <TextField
              margin="dense"
              name="url"
              label="Destination Website URL"
              type="text"
              fullWidth
              required
              value={newQRCode.url}
              onChange={handleChange}
              error={!!urlError || (!newQRCode.url && showErrors)}
              helperText={
                urlError ||
                (!newQRCode.url && showErrors ? "URL is required." : "")
              }
            />
            <TextField
              margin="dense"
              name="description"
              label="Description"
              type="text"
              fullWidth
              value={newQRCode.description}
              onChange={handleChange}
            />
            <Typography gutterBottom>QR Code Size (pixels)</Typography>
            <Slider
              value={newQRCode.size}
              onChange={handleSliderChange}
              aria-labelledby="size-slider"
              valueLabelDisplay="auto"
              step={10}
              marks
              min={100}
              max={500}
            />

            {/* Image Selection Section */}
            <Typography variant="body1" style={{ marginTop: "16px" }}>
              Optional: Upload an image to overlay on the QR code.
            </Typography>
            <Typography variant="body2">
              Max size 300K - Recommended size 150x150 pixels
            </Typography>
            <Typography variant="body2">
              If image has text make sure QR Code Size is at least 375 to ensure
              that the text is readable.
            </Typography>

            {newQRCode.qrImage?.data ? (
              <div
                style={{
                  marginTop: "16px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={`data:image/png;base64,${bufferToBase64(
                    newQRCode.qrImage.data
                  )}`}
                  alt="QR Code Logo"
                  style={{
                    width: "200px",

                    borderRadius: "8px",
                    objectFit: "contain",
                    marginBottom: "8px",
                  }}
                />
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleDeleteImage}
                  style={{ marginTop: "8px" }}
                >
                  Remove Image
                </Button>
              </div>
            ) : (
              <div style={{ marginTop: "16px" }}>
                <input
                  accept="image/*"
                  type="file"
                  id="image-upload"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <label htmlFor="image-upload">
                  <Button variant="outlined" component="span">
                    Upload Image
                  </Button>
                </label>
              </div>
            )}

            {fileError && (
              <DialogContentText color="error" style={{ marginTop: "8px" }}>
                {fileError}
              </DialogContentText>
            )}

            {loading && (
              <div style={{ marginTop: "16px", textAlign: "center" }}>
                <CircularProgress />
                <Typography variant="body2" style={{ marginTop: "8px" }}>
                  Resizing image, please wait...
                </Typography>
              </div>
            )}

            {/* Image Preview */}
            {imagePreview && (
              <div style={{ marginTop: "16px", textAlign: "center" }}>
                <img
                  src={imagePreview}
                  alt="Image Preview"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "200px",
                    objectFit: "contain",
                    borderRadius: "8px",
                  }}
                />
              </div>
            )}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            disabled={loading ? true : false}
          >
            {editQRCode ? "Save" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MemberQRTab;
