// src/components/HealthCheck.js

import React from "react";

const HealthCheck = () => {
  return (
    <div>
      <h1>Success - All Good</h1>
    </div>
  );
};

export default HealthCheck;
